<template>
  <v-popover
    ref="popover"
    popoverClass="popover-edit"
    :placement="placement"
    :disabled="!canEdit"
    offset="10px"
    :handleResize="true"
  >
    <slot></slot>
    <template slot="popover">
      <div class="tooltip-edit-content">
        <div class="surface">
          <label>Planifié:</label>
          <b-form-input
            readonly
            :value="lead[field]"
            type="number"
          ></b-form-input>
        </div>
        <div class="surface">
          <label for="surface-pose-edit">Posé:</label>
          <b-form-input
            id="surface-pose-edit"
            v-model="v"
            type="number"
          ></b-form-input>
        </div>
      </div>

      <div class="action">
        <b-button
          variant="outline-secondary"
          size="sm"
          @click="hundlePopoverCancel()"
        >
          Annuler
        </b-button>
        <b-button variant="success" size="sm" @click="hundlePopoverOk()">
          Enregistrer
        </b-button>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions } from 'vuex'

Vue.component('v-popover', VPopover)

export default {
  name: 'TooltipEdit',
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    field: {
      type: String,
      required: true
    },
    canEdit: { default: false },
    weekId: { required: true },
    lead: { required: true },
    day: { required: true },
    technician: { required: true }
  },
  data() {
    return {
      v: this.lead[this.field + '_pose']
    }
  },
  methods: {
    ...mapActions(['updateFieldPose']),
    hundlePopoverCancel() {
      this.$refs['popover'].hide()
    },
    async hundlePopoverOk() {
      const payload = {
        field: this.$options.filters.nom_pose(this.field + '_pose'),
        value: this.v
      }
      if (this.v != this.lead[this.field + '_pose']) {
        await this.updateFieldPose({
          change: payload,
          weekId: this.weekId,
          lead: this.lead,
          day: this.day,
          field: this.field,
          technician: this.technician
        })
      }
      this.$refs['popover'].hide()
    }
  },
  filters: {
    nom_pose: function(value) {
      switch (value) {
        case 'surface_mur_pose':
          return 'm_pose'
        case 'surface_plafond_pose':
          return 'p_pose'
        case 'surface_deroule_pose':
          return 'd_pose'
        case 'surface_rampant_pose':
          return 'r_pose'
        case 'surface_souffle_pose':
          return 's_pose'
        case 'surface_pignon_pose':
          return 'pignon_pose'
        case 'surface_vide_sanitaire_pose':
          return 'vs_pose'
        case 'surface_iti_pose':
          return 'iti_pose'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-edit {
    opacity: 1;
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      background: black;
      color: white;
      border-radius: 16px;
      padding: 16px 0 0;
      text-align: left;
      max-width: unset;
      width: 220px;

      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .form-group {
        margin: 0 16px 10px;
      }

      .tooltip-edit-content {
        padding: 5px 10px;
        .surface {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
          label {
            width: 80px;
            margin: 0;
            color: #626262;
          }

          .value,
          input {
            font-size: 12px;
            color: #626262;
            font-weight: 700;
            width: 80px;
          }
        }
      }
      .action {
        margin: 0;
        padding: 8px 16px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #f7f7f7;
        button {
          &:first-child {
            margin-right: 4px;
          }
        }
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
